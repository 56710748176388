import { useStore } from "react-redux";

import Button from "../../Button/Index";

import Styles from "./Style.module.css";

function FirstEvent({ handleClick }) {
  const store = useStore();
  const mode = store.getState().mode.mode;

  return (
    <div className={`${Styles.FirstEventModal}  ${Styles[mode]}`}>
      <div className={Styles.FirstEventModalHeader}>
        <p>Welcome to Calendar</p>
      </div>
      <div className={`${Styles.FirstEventModalBody}  ${Styles[mode]}`}>
        <span className={Styles.FirstEventModalBodySpan}>
          Welcome on your Calendar. You can now create events in the left
          sidebar.
        </span>
      </div>
      <div className={Styles.FirstEventModalFooter}>
        <Button
          typeOf={mode === "Dark" ? "DarkMode" : "DarkBlueButton"}
          handleClick={handleClick}
          buttonName="Create event"
        />
      </div>
    </div>
  );
}

export default FirstEvent;
