export const year =[[],[],[],[],[],[],[],[],[],[],[],[]];


year[0][0] = {week: 1, place: 6, date: 1, month: "January", monthOrder: 0, year:2022, count: 1 };

const realDate = new Date()
const realYear = realDate.getFullYear()
const day = realDate.getDay()
const today = realDate.getDate()
const hour = realDate.getHours()

let realDay
let month = realDate.getMonth()
let realMonth
let currentMonthLength

switch (day) {
  case 0:
    realDay = "Sunday";
    break;
  case 1:
    realDay = "Monday";
    break;
  case 2:
    realDay = "Tuesday";
    break;
  case 3:
    realDay = "Wednesday";
    break;
  case 4:
    realDay = "Thursday";
    break;
  case 5:
    realDay = "Friday";
    break;
  case 6:
    realDay = "Saturday";
    break;
  default:
    realDay = "";
}

for(let i=0; i<year.length; i++){
  month = i
  switch (month) {
    case 0:
      realMonth = "January";
      currentMonthLength = 31
      break;
    case 1:
      realMonth = "February";
      currentMonthLength = 28
      break;
    case 2:
      realMonth = "March";
      currentMonthLength = 31
      break;
    case 3:
      realMonth = "April";
      currentMonthLength = 30
      break;
    case 4:
      realMonth = "May";
      currentMonthLength = 31
      break;
    case 5:
      realMonth = "June";
      currentMonthLength = 30
      break;
    case 6:
      realMonth = "July";
      currentMonthLength = 31
      break;
    case 7:
      realMonth = "August";
      currentMonthLength = 31
      break;
    case 8:
      realMonth = "September";
      currentMonthLength = 30
      break;
    case 9:
      realMonth = "October";
      currentMonthLength = 31
      break;
    case 10:
      realMonth = "November";
      currentMonthLength = 30
      break;
    case 11:
      realMonth = "December";
      currentMonthLength = 31
      break;
    default:
      realMonth = "YOLO";
  };
  
  if(i===0){
    for(let y=0; y<currentMonthLength; y++){
      if(i===0 && y===0){
      }else if(year[i][y-1].place<6){
        year[i][y] = {week: year[i][y-1].week, place: year[i][y-1].place+1, date:year[i][y-1].date+1, month: year[i][y-1].month, year:realYear, count:year[i][y-1].count+1, monthOrder: i}
      }else if(year[i][y-1].place===6){
        year[i][y] = {week: year[i][y-1].week+1, place: 0, date:year[i][y-1].date+1, month: year[i][y-1].month, year:realYear, count:year[i][y-1].count+1, monthOrder: i}
      }
    }
  }else{
    for(let y=0; y<=currentMonthLength-1; y++){
      if(y===0 && year[i-1][year[i-1].length-1].place < 6){
        year[i][y] = {week:year[i-1][year[i-1].length-1].week, place: year[i-1][year[i-1].length-1].place+1, date: 1, month: realMonth, year: realYear, count:year[i-1][year[i-1].length-1].count+1, monthOrder: i}
      }else if(y===0 && year[i-1][year[i-1].length-1].place === 6){
        year[i][y] = {week:year[i-1][year[i-1].length-1].week+1, place: 0, date:1, month: realMonth, year: realYear,  count:year[i-1][year[i-1].length-1].count+1, monthOrder: i}
      }else if(year[i][y-1].place<6){
        year[i][y] = {week: year[i][y-1].week, place: year[i][y-1].place +1, date:year[i][y-1].date+1, month: realMonth, year: realYear, count:year[i][y-1].count+1, monthOrder: i}
      }else{
        year[i][y] = {week: year[i][y-1].week+1, place: 0, date:year[i][y-1].date+1, month: realMonth, year: realYear, count:year[i][y-1].count+1, monthOrder: i}
      }
    }
  }
}

month = realDate.getMonth()
switch (month) {
  case 0:
    realMonth = "January";
    currentMonthLength = 31
    break;
  case 1:
    realMonth = "February";
    currentMonthLength = 29
    break;
  case 2:
    realMonth = "March";
    currentMonthLength = 31
    break;
  case 3:
    realMonth = "April";
    currentMonthLength = 30
    break;
  case 4:
    realMonth = "May";
    currentMonthLength = 31
    break;
  case 5:
    realMonth = "June";
    currentMonthLength = 30
    break;
  case 6:
    realMonth = "July";
    currentMonthLength = 31
    break;
  case 7:
    realMonth = "August";
    currentMonthLength = 31
    break;
  case 8:
    realMonth = "September";
    currentMonthLength = 30
    break;
  case 9:
    realMonth = "October";
    currentMonthLength = 31
    break;
  case 10:
    realMonth = "November";
    currentMonthLength = 30
    break;
  case 11:
    realMonth = "December";
    currentMonthLength = 31
    break;
  default:
    realMonth = "YOLO";
};

export const currentDay = {
  week:  year.filter(elem => elem[0].month === realMonth)[0].filter(element => element.date===today)[0].week,
  place: day,
  day: realDay,
  date: today,
  month: realMonth,
  monthOrder: month,
  year: realYear,
  count: year.filter(elem => elem[0].month === realMonth)[0].filter(element => element.date===today)[0].count,
};

for(let i =0; i<year.length; i++){
  for(let y=0; y<year[i].length; y++){
    let jour
    switch (year[i][y].place) {
      case 0:
        jour = "Sunday";
        break;
      case 1:
        jour = "Monday";
        break;
      case 2:
        jour = "Tuesday";
        break;
      case 3:
        jour = "Wednesday";
        break;
      case 4:
        jour = "Thursday";
        break;
      case 5:
        jour = "Friday";
        break;
      case 6:
        jour = "Saturday";
        break;
      default:
        jour = "";
    }
    year[i][y] = {...year[i][y], day: jour}
  }
};

export const currentWeek = [];
  for(let i =0; i<year.length; i++){
    for(let y = 0; y<year[i].length; y++){
      if(year[i][y].week === currentDay.week){
        currentWeek.push(year[i][y])
      }
    }
  }

export const hours = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00", "20:00","21:00", "22:00", "23:00"]

export const timer = hour.toString()