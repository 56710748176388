import Inscription from "../../components/Inscription/Index";

import Styles from "./Style.module.css";

function CreateAccount() {
  return (
    <div className={Styles.LandingScreen}>
        <Inscription  />
      <span className={Styles.LandingPageFooter}>© Pelegrand 2022</span>
    </div>
  );
}

export default CreateAccount;
