import { useStore } from "react-redux";

import Button from "../../Button/Index";

import Styles from "../Style.module.css";

function ModeModal({
  position,
  long,
  modalButtonName,
  handleClick,
  displayModeModal,
  setDisplayModeModal,
}) {
  const store = useStore();
  const globalMode = store.getState().mode.mode;
  const mode = ["Dark", "White"];
  const showModeModal = () => {
    setDisplayModeModal(!displayModeModal);
  };

  return (
    <div>
      <Button
        handleClick={showModeModal}
        typeOf={globalMode === "Dark" ? "DarkMode" : "WhiteButton"}
        buttonName={modalButtonName}
      />
      {displayModeModal && (
        <div
          className={`${Styles.Modal} ${Styles[position]} ${Styles[long]} ${Styles[globalMode]}`}
        >
          <ul className={Styles.ModalList}>
            {mode.map((element, index) => {
              return (
                <div
                  key={index}
                  className={`${Styles.ModalListLi} ${Styles[globalMode]}`}
                  onClick={(e) => handleClick(e.target.textContent)}
                >
                  <li value={element}>{element}</li>
                </div>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ModeModal;
