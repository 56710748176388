import { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";

import Styles from "./Style.module.css";
import AddContact from "../../assets/icons/add-contact.png";
import Button from "../Button/Index";

export const ContactSearch = (props) => {
  const [filtredList, setFiltredList] = useState(props.contactlist);
  const [showList, setShowList] = useState(false);
  const [newContact, setNewContact] = useState("");
  const [showNewContact, setShowNewContact] = useState(false);
  const [askContactModal, setAskContactModal] = useState(false);

  const BASE_URL = process.env.REACT_APP_API_URL;
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const handleSearch = (e) => {
    setNewContact(e.target.value);
    if (e.target.value !== "") {
      setFiltredList(
        props.contactlist.filter((ele) =>
          ele.email
            .substring(0, ele.email.indexOf("@"))
            .includes(e.target.value)
        )
      );
      setShowList(true);
      if (filtredList.length === 0) {
        if (regex.test(e.target.value)) {
          setShowNewContact(true);
        } else {
          setShowNewContact(false);
        }
      }
    } else {
      setShowList(false);
      setNewContact("");
    }
  };

  const handleCreateContact = () => {
    setAskContactModal(!askContactModal);
  };

  const handleSendContact = async () => {
    const data = {
      askedBy: props.id,
      email: newContact,
    };
    await axios.post(`${BASE_URL}/users/${props.id}/contacts`, data, {
      headers: { Authorization: "bearer " + props.token },
    });
    setAskContactModal(!askContactModal);
    setNewContact("");
    setShowNewContact(false);
  };

  const handleCloseModal = () => {
    setAskContactModal(false);
  };

  return (
    <div>
      <p className={Styles.ContactSearchTitle}>Contacts</p>
      <div className={Styles.ContactSearchContainer}>
        <input
          placeholder="search for contacts"
          className={Styles.ContactSearchInput}
          onChange={handleSearch}
          value={newContact}
        ></input>
        {showList &&
          filtredList.length < 5 &&
          filtredList.map((element) => {
            return (
              <div className={Styles.Contact}>
                <p>{element.email}</p>
              </div>
            );
          })}
        {showNewContact && (
          <div className={Styles.Contact}>
            <div className={Styles.CreateContactContactBox}>
              <p className={Styles.AskContact}>{newContact}</p>
            </div>
            <div
              className={Styles.CreateContactLogoBox}
              onClick={handleCreateContact}
            >
              <img
                src={AddContact}
                className={Styles.AddContactLogo}
                alt="Add-contact"
              />
            </div>
          </div>
        )}
      </div>
      {askContactModal && (
        <div className={Styles.AskContactModal} onClick={handleCloseModal}>
          <p className={Styles.AskContactModalMessage}>
            Ask <span className={Styles.NewContactName}>{newContact}</span> to
            join your network?
          </p>
          <div>
            <Button
              typeOf="GreenButton"
              buttonName="Invite"
              handleClick={handleSendContact}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contactlist: state.contactlist.contactlist,
    token: state.authentication.token,
    id: state.authentication.id,
    email: state.authentication.email,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContactSearch);
