import { useNavigate } from "react-router-dom";
import { useStore } from "react-redux";
import { connect } from "react-redux";

import { setSignOut } from "../../../actions";
import Button from "../../Button/Index";

import Styles from "./Style.module.css";

function UserModal(props) {
  const store = useStore();
  const userName = store.getState().authentication.username;
  const userEmail = store.getState().authentication.email;
  const mode = store.getState().mode.mode;
  const navigate = useNavigate();

  const handleSignOut = () => {
    props.setSignOut();
    navigate("/");
  };

  return (
    <div className={`${Styles.UserModalContainer} ${Styles[mode]}`}>
      <div className={`${Styles.UserModalHeader}  ${Styles[mode]}`}>
        <div className={`${Styles.UserModalHeaderLogo}  ${Styles[mode]}`}>
          <p>{userName.substring(0, 1)}</p>
        </div>
        <div className={`${Styles.UserModalHeaderDetails}  ${Styles[mode]}`}>
          <p className={`${Styles.UserModalHeaderUserName} ${Styles[mode]}`}>
            {userName}
          </p>
          <p className={`${Styles.UserModalHeaderEmail} ${Styles[mode]}`}>
            {userEmail}
          </p>
        </div>
      </div>
      <div className={Styles.UserModalBody}>
        <Button
          typeOf={mode === "Dark" ? "DarkMode" : "WhiteButton"}
          buttonName="Sign out"
          handleClick={handleSignOut}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSignOut: setSignOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
