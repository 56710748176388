import { SET_AUTHENTICATION, SET_SIGNOUT } from "../actions/actions-types";

const initialState = {
  isLoggedIn: false,
  id: null,
  token: null,
  email: "",
  username: "",
};

export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATION:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        id: action.payload.id,
        token: action.payload.token,
        email: action.payload.email,
        username: action.payload.username,
      };

    case SET_SIGNOUT:
      return {
        ...state,
        id: null,
        isLoggedIn: false,
        token: null,
        email: "",
        username: "",
      };

    default:
      return state;
  }
}
