import axios from "axios";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { setAuthentication } from "../../actions/index";
import { setContactList } from "../../actions/index";
import Button from "../Button/Index";

import Styles from "./Style.module.css";

function Connexion(props) {
  const { register, handleSubmit } = useForm();
  const BASE_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  async function onSubmit(data) {
    try {
      const result = await axios.post(`${BASE_URL}/users/login`, data);
      props.setAuthentication({
        isLoggedIn: true,
        id: result.data.id,
        token: result.headers.accesstoken,
        email: data.email,
        username: result.data.username,
      });
      const contact = await axios.get(`${BASE_URL}/users/${result.data.id}/contacts`,{
        headers: { Authorization: "bearer " + result.headers.accesstoken },
      })
      props.setContactList({contactlist: contact.data.contactlist})
      navigate("/calendar");
    } catch (err) {
      if (err.message === "Request failed with status code 401") {
        alert("the email address or password provided is incorrect");
      }
      navigate("/");
    }
  }

  const handleSetInscription = () => {
    props.setInscription(true);
  };

  return (
    <div className={Styles.ConnexionContainer}>
      <h1>Calendar</h1>
      <span className={Styles.ConnexionSigninP}>Sign in</span>
      <span>to continue with Calendar</span>
      <form onSubmit={handleSubmit(onSubmit)} className={Styles.ConnexionForm}>
        <div className={Styles.ConnexionInputContainer}>
          <input
            placeholder="email"
            {...register("email")}
            className={Styles.ConnexionInput}
            required
          />
        </div>
        <div className={Styles.ConnexionInputContainer}>
          <input
            placeholder="password"
            type="password"
            {...register("password")}
            className={Styles.ConnexionInput}
            required
          />
        </div>
        <div className={Styles.ConnexionFooter}>
          <p className={Styles.ConnexionBlueP} onClick={handleSetInscription}>
            Create account
          </p>
          <Button
            type="submit"
            typeOf="DarkBlueButton"
            buttonName="Connexion"
          />
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  setAuthentication,
  setContactList
};

export default connect(mapStateToProps, mapDispatchToProps)(Connexion);
