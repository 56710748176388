import { useState } from "react";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button/Index";
import CreateBar from "../../components/CreateBar/Index";
import CalendarBox from "../../components/CalendarBox/Index";
import { year, currentWeek, currentDay } from "../../components/CalendarBox/newData";
import Footer from "../../components/Footer/Index";
import Selection from "../../components/Selection/Index";
import useWindowDimensions from "../../hooks/UseWindowDimensions/Index";

import Styles from "./Style.module.css";

function Calendar() {
  const { width } = useWindowDimensions();
  let displayDayState = false;
  let displayWeekState = true;
  width < 575.98 ? (displayDayState = currentDay) : (displayDayState = false);
  width < 575.98 ? (displayWeekState = false) : (displayWeekState = true);
  const [buttonName, setButtonName] = useState("Week");
  const [showCreateBar, setShowCreateBar] = useState(false);
  const [showBar, setShowBar] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(currentWeek);
  const [selectedDay, setSelectedDay] = useState(currentDay);
  const [displayWeek, setDisplayWeek] = useState(displayWeekState);
  const [displayDay, setDisplayDay] = useState(displayDayState);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModeModal, setDisplayModeModal] = useState(false);
  
  const currentYear = year ;
  const store = useStore();
  const status = store.getState().authentication.isLoggedIn;
  const navigate = useNavigate();

  const handleShowCreateBar = () => {
    setShowCreateBar(!showCreateBar);
    // setTimeout(()=>{
    //   setShowBar(!showBar)
    // }, 1000)
  };

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <>
      {status ? (
        <div className={Styles.CalendarScreen}>
          <Selection
            handleClick={handleShowCreateBar}
            currentWeek={currentWeek}
            currentYear={currentYear}
            selectedWeek={selectedWeek}
            setSelectedWeek={setSelectedWeek}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            displayWeek={displayWeek}
            setDisplayWeek={setDisplayWeek}
            displayDay={displayDay}
            setDisplayDay={setDisplayDay}
            buttonName={buttonName}
            setButtonName={setButtonName}
            displayModal={displayModal}
            setDisplayModal={setDisplayModal}
            displayModeModal={displayModeModal}
            setDisplayModeModal={setDisplayModeModal}
          />
          <div className={Styles.CalendarScreenBody}>
            {showCreateBar && (
                <CreateBar
                  selectedDay={selectedDay}
                  selectedWeek={selectedWeek}
                />
            )}
            <CalendarBox
              year={currentYear}
              selectedWeek={selectedWeek}
              setSelectedWeek={setSelectedWeek}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              displayWeek={displayWeek}
              setDisplayWeek={setDisplayWeek}
              displayDay={displayDay}
              setDisplayDay={setDisplayDay}
              buttonName={buttonName}
              setButtonName={setButtonName}
              setShowCreateBar={setShowCreateBar}
            />
          </div>
        </div>
      ) : (
        <div className={Styles.WelcomeContainer}>
          <h1 className={Styles.ErrorCode}>401</h1>
          <h2 className={Styles.ErrorMessage}>Unauthorized access</h2>
          <p className={Styles.ErrorMessageDescription}>
            You must be logged in to access this part of Calendar!
          </p>
          <div>
            <Button
              typeOf="BlueButton"
              buttonName="go back"
              handleClick={handleGoBack}
            />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default Calendar;
