import {
  SET_AUTHENTICATION,
  SET_USER_DATA,
  SET_SIGNOUT,
  SET_MODE,
  SET_USER_PREFERENCES,
  SET_CONTACT_LIST,
} from "./actions-types";

export function setAuthentication({ isLoggedIn, id, token, email, username }) {
  return {
    type: SET_AUTHENTICATION,
    payload: { isLoggedIn, id, token, email, username },
  };
}

export function setSignOut() {
  return {
    type: SET_SIGNOUT,
  };
}

export function setUserData({ userData }) {
  return {
    type: SET_USER_DATA,
    payload: userData
  };
}

export function setUserPreferences({ userPreferences }) {
  return {
    type: SET_USER_PREFERENCES,
    payload: userPreferences
  };
}

export function setMode({ mode }) {
  return {
    type: SET_MODE,
    payload: mode,
  };
}

export function setContactList({ contactlist }) {
  return {
    type: SET_CONTACT_LIST,
    payload: contactlist,
  };
}
