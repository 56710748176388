import { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { setUserData } from "../../actions";

import Styles from "./Style.module.css";
import Cancel from "../../assets/icons/cancel.png";
import Button from "../Button/Index";

function Event(props) {
  const typeOf = props.typeOf;
  const mode = props.mode.mode;
  const token = props.authentication.token;
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [showDetails, setShowDetails] = useState(false);

  const handleDisplayDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleDeleteEvent = () => {
    axios.delete(`${BASE_URL}/events/${parseInt(props.event.id)}`, {
      headers: { Authorization: "bearer " + token },
    });
    props.setUserData({
      userData: props.userData.filter(
        (element) => element.id !== props.event.id
      ),
    });
  };

  return (
    <div
      className={`${Styles.Event} ${Styles[typeOf]} ${Styles[mode]} ${
        Styles[props.type]
      }`}
      onClick={handleDisplayDetails}
    >
      <p
        className={`${Styles.EventDescription} ${Styles[typeOf]} ${Styles[mode]}`}
      >
        {props.eventDescription}
      </p>
      {showDetails === true && (
        <div className={`${Styles.EventModalDescription} ${Styles[mode]}`}>
          <div
            className={`${Styles.EventModalDescriptionHeader} ${Styles[mode]}`}
          >
            <div className={Styles.CancelButtonContainer}>
              <img
                src={Cancel}
                alt="cancel"
                className={Styles.CancelButton}
                onClick={handleDisplayDetails}
              />
            </div>
          </div>
          <div className={Styles.EventModalDescriptionTitleContainer}>
            <h2 className={Styles.EventModalDescriptionTitle}>
              {props.event.content}
            </h2>
            <div
              className={`${Styles.EventModalDescriptionTypeOf}  ${Styles[typeOf]} `}
            ></div>
          </div>
          <div className={Styles.EventModalDescriptionTypeContainer}>
            <p className={`${Styles.EventModalDescriptionTitle}`}>
              {props.event.type.charAt(0).toUpperCase() +
                props.event.type.slice(1)}
            </p>
          </div>
          <div className={Styles.EventModalDescriptionTypeContainer}>
            <p className={Styles.EventModalDescriptionTitle}>
              {props.event.parsedDay} {props.event.parsedM}{" "}
              {props.event.parsedY} {props.event.parsedTime}
            </p>
          </div>
          <div className={Styles.EventModalDescriptionDescriptionContainer}>
            <div className={Styles.EventModalDescriptionDescription}>
              <p className={Styles.EventModalDescriptionTitle}>
                {props.event.description}
              </p>
            </div>
          </div>
          <div className={Styles.EventModalDescriptionFooter}>
            <Button
              typeOf="RedButton"
              buttonName="Delete"
              handleClick={handleDeleteEvent}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    mode: state.mode,
    authentication: state.authentication,
    userData: state.userdata.userData,
  };
};

const mapDispatchToProps = {
  setUserData: setUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);
