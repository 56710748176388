import { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";

import Event from "../Event/Index";
import FirstEvent from "../Modal/FirstEvent/Index";
import CreateEvent from "../Modal/CreateEvent/Index";
import { setUserData } from "../../actions";
import { currentDay, hours, timer } from "./newData";

import Styles from "./Style.module.css";

function CalendarBox(props) {
  const [week, setWeek] = useState(props.selectedWeek);
  const [displayFistEventModal, setDisplayFirstEventModal] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [selectedHour, setSelectedHour] = useState();
  const mode = props.mode.mode;
  const userId = props.userId;
  const BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setWeek(props.selectedWeek);
    const fetchUserDate = async () => {
      try {
        const result = await axios.get(`${BASE_URL}/events/?user=${userId}`, {
          headers: { Authorization: "bearer " + props.token },
        });
        props.setUserData({ userData: result.data });
      } catch (err) {
        setDisplayFirstEventModal(true);
      }
    };
    fetchUserDate();
  }, []);

  const handleDisplayDay = (date) => {
    setShowCreateEventModal(false);
    props.setDisplayWeek(false);
    props.setDisplayDay(date);
    props.setSelectedDay(date);
    props.setButtonName("Day");
  };

  const closeFirstEventModal = () => {
    setDisplayFirstEventModal(false);
    props.setShowCreateBar(true);
  };

  const sortedUserEvents = props.userEvents.filter((ele) =>
    props.userPreferences.includes(ele.type)
  );

  const handleSelectHourRow = (e) => {
    let target = {
      year: Number(e.dataset.year),
      date: Number(e.dataset.date),
      day: e.dataset.day,
      month: e.dataset.month,
      monthOrder: e.dataset.monthorder,
      place: Number(e.dataset.place),
      count: Number(e.dataset.count),
      week: Number(e.dataset.week),
    };
    setSelectedHour(e.dataset.hour);
    props.setSelectedDay(target);
    setShowCreateEventModal(!showCreateEventModal);
  };

  return (
    <div className={`${Styles.CalendarContainer} ${Styles[mode]}`}>
      {props.displayWeek && (
        <div className={Styles.CalendarContainerHeader}>
          <div className={Styles.CalendarContainerHeaderHourColumn}>
            <span></span>
          </div>
          {week &&
            props.selectedWeek.map((days, index) => {
              return (
                <div
                  key={index}
                  className={Styles.CalendarContainerColumnHeader}
                >
                  <p
                    className={`${Styles.CalendarContainerColunmHeaderTitle} ${Styles[mode]}`}
                  >
                    {days.day.substring(0, 3).toUpperCase()}
                  </p>
                  {days.day === currentDay.day &&
                  days.date === currentDay.date &&
                  days.month === currentDay.month ? (
                    <div
                      className={`${Styles.CalendarContainerHeaderCurrentDay} ${Styles[mode]}`}
                    >
                      <p onClick={() => handleDisplayDay(days)}>{days.date}</p>
                    </div>
                  ) : (
                    <div
                      className={`${Styles.CalendarContainerHeaderOtherDay} ${Styles[mode]}`}
                    >
                      <p onClick={() => handleDisplayDay(days)}>{days.date}</p>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      )}
      {props.displayDay && (
        <div className={`${Styles.DisplayDayContainer} ${Styles[mode]}`}>
          <div className={Styles.CalendarContainerColumnHeader}>
            <p
              className={`${Styles.CalendarContainerColunmHeaderTitle} ${Styles[mode]}`}
            >
              {props.displayDay.day.substring(0, 3).toUpperCase()}
            </p>
          </div>
          {props.displayDay.day === currentDay.day &&
          props.displayDay.date === currentDay.date &&
          props.displayDay.month === currentDay.month ? (
            <div
              className={`${Styles.CalendarContainerHeaderCurrentDay} ${Styles[mode]}`}
            >
              <p>{props.displayDay.date}</p>
            </div>
          ) : (
            <div
              className={`${Styles.CalendarContainerHeaderOtherDay} ${Styles[mode]}`}
            >
              <p>{props.displayDay.date}</p>
            </div>
          )}
        </div>
      )}
      <div className={Styles.CalendarContainerColumnBodyContainer}>
        <div className={Styles.CalendarContainerBodyHourColumn}>
          {hours.map((hour, index) => {
            return (
              <div
                key={index}
                className={`${Styles.CalendarContainerHourSpan} ${Styles[mode]}`}
              >
                <span>{hour}</span>
              </div>
            );
          })}
        </div>
        {props.displayWeek &&
          week &&
          props.selectedWeek.map((days, index) => {
            return (
              <div
                key={index}
                className={`${Styles.CalendarContainerColumnBody} ${Styles[mode]}`}
              >
                {hours.map((hour) => {
                  return (
                    <div
                      key={hour}
                      className={`${Styles.CalendarContainerHourRow} ${Styles[mode]}`}
                    >
                      {props.userEvents &&
                        sortedUserEvents
                          .filter(
                            (event) =>
                              event.parsedDay === days.date &&
                              event.parsedM === days.month &&
                              event.parsedY === days.year &&
                              event.parsedTime.substring(0, 2) ===
                                hour.substring(0, 2)
                          )
                          .map((event, index) => {
                            return (
                              <Event
                                key={index}
                                typeOf={event.type}
                                eventDescription={event.content}
                                event={event}
                              />
                            );
                          })}
                      <div
                        className={Styles.AddEventDiv}
                        onClick={(e) => handleSelectHourRow(e.target)}
                        data-day={days.day}
                        data-year={days.year}
                        data-week={days.week}
                        data-place={days.place}
                        data-monthorder={days.monthOrder}
                        data-month={days.month}
                        data-count={days.count}
                        data-date={days.date}
                        data-hour={hour}
                      >
                        {hour.slice(0, hour.indexOf(":")) === timer &&
                          days.date === currentDay.date &&
                          days.month === currentDay.month && (
                            <>
                              <div
                                className={`${Styles.RoundTimer} ${Styles[mode]}`}
                              ></div>
                              <div
                                className={`${Styles.Timer} ${Styles[mode]}`}
                              ></div>
                            </>
                          )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        {props.displayDay && (
          <div className={Styles.DisplayDayRowContainer}>
            {hours.map((hour) => {
              return (
                <div
                  key={hour}
                  className={`${Styles.DisplayDayRow} ${Styles[mode]}`}
                >
                  {props.userEvents &&
                    sortedUserEvents
                      .filter(
                        (event) =>
                          event.parsedDay === props.displayDay.date &&
                          event.parsedM === props.displayDay.month &&
                          event.parsedY === props.displayDay.year &&
                          event.parsedTime.substring(0, 2) ===
                            hour.substring(0, 2)
                      )
                      .map((event, index) => {
                        return (
                          <Event
                            key={index}
                            type="dayEvent"
                            typeOf={event.type}
                            eventDescription={event.content}
                            event={event}
                          />
                        );
                      })}
                  <div
                    className={Styles.AddEventDiv}
                    onClick={(e) => handleSelectHourRow(e.target)}
                    data-day={props.displayDay.day}
                    data-year={props.displayDay.year}
                    data-week={props.displayDay.week}
                    data-place={props.displayDay.place}
                    data-monthorder={props.displayDay.monthOrder}
                    data-month={props.displayDay.month}
                    data-count={props.displayDay.count}
                    data-date={props.displayDay.date}
                    data-hour={hour}
                  >
                    {hour.slice(0, hour.indexOf(":")) === timer &&
                      props.displayDay.date === currentDay.date &&
                      props.displayDay.month === currentDay.month && (
                        <>
                          <div
                            className={`${Styles.RoundTimer} ${Styles[mode]}`}
                          ></div>
                          <div
                            className={`${Styles.Timer} ${Styles[mode]}`}
                          ></div>
                        </>
                      )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {displayFistEventModal && (
        <FirstEvent
          displayFirstEventModal={displayFistEventModal}
          setDisplayFirstEventModal={setDisplayFirstEventModal}
          handleClick={closeFirstEventModal}
        />
      )}
      {showCreateEventModal && (
        <CreateEvent
          selectedDay={props.selectedDay}
          selectedWeek={props.selectedWeek}
          modalState={showCreateEventModal}
          displayIcon="true"
          hour={selectedHour}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    mode: state.mode,
    userId: state.authentication.id,
    token: state.authentication.token,
    userEvents: state.userdata.userData,
    userPreferences: state.userPreferences.userPreferences,
  };
};

const mapDispatchToProps = {
  setUserData: setUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarBox);
