import { SET_USER_PREFERENCES } from "../actions/actions-types";

const initialState = {
  userPreferences: [ 'appointment',
  'event',
  'meeting',
  'task',
  'reminder']
}

export default function userPreferencesReducer(state = initialState, action){
  switch(action.type){
    case SET_USER_PREFERENCES : 
      return{
        ...state,
        userPreferences: action.payload
      }
    default :
      return state;
  }
}