import { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useStore } from "react-redux";
import { useForm } from "react-hook-form";

import Button from "../../Button/Index";
import { setUserData } from "../../../actions";
import { currentWeek, hours, year } from "../../CalendarBox/newData";

import Styles from "./Style.module.css";
import Cancel from "../../../assets/icons/cancel.png";
import Arrow from "../../../assets/icons/left-chevron.png";

export const eventTypes = [
  { name: "Event", sub: "Event" },
  { name: "Meeting", sub: "Meet" },
  { name: "Appointment", sub: "App" },
  { name: "Task", sub: "Task" },
  { name: "Reminder", sub: "Rem" },
];

function CreateEvent(props) {
  const [displayCreateEventModal, setDisplayCreateEventModal] = useState(
    props.modalState
  );
  const [showSelect, setShowSelect] = useState(false);
  const [selectedType, setSelectedType] = useState("Event");
  const [sortedMonth, setSortedMonth] = useState(
    year.filter((month) => month[0].month === props.selectedDay.month)[0]
  );
  const [eventDate, setEventDate] = useState();
  const store = useStore();
  const mode = store.getState().mode.mode;
  const userId = store.getState().authentication.id;

  const { register, handleSubmit } = useForm();

  const BASE_URL = process.env.REACT_APP_API_URL;

  const handleShowModal = () => {
    setDisplayCreateEventModal(!displayCreateEventModal);
    setSortedMonth(
      year.filter((month) => month[0].month === props.selectedDay.month)[0]
    );
  };

  const handleSelectType = (e) => {
    setSelectedType(e.target.id);
  };
  const onSubmit = async (data) => {
    let parsedMonth;
    let parsedDate = props.selectedDay.date;
    const events = [...props.userData];

    if (sortedMonth[0].monthOrder < 10) {
      parsedMonth = `0${sortedMonth[0].monthOrder + 1}`;
    } else {
      parsedMonth = `${sortedMonth[0].monthOrder + 1}`;
    }

    if (eventDate !== undefined && eventDate < 10) {
      parsedDate = `0${eventDate}`;
    } else if (eventDate !== undefined) {
      parsedDate = `${eventDate}`;
    } else {
      if (parsedDate < 10) {
        parsedDate = `0${props.selectedDay.date}`;
      }
    }

    let realHour;
    if (props.hour) {
      props.hour === "00:00"
        ? (realHour = Number(props.hour.substring(0, 2)))
        : (realHour = Number(props.hour.substring(0, 2) ));
    } else {
      data.hour === "00:00"
        ? (realHour = Number(data.hour.substring(0, 2)))
        : (realHour = Number(data.hour.substring(0, 2) ));
    }

    if (realHour < 10) {
      realHour = `0${realHour.toString()}`;
    } else {
      realHour = realHour.toString();
    }

    const date = `${sortedMonth[0].year}-${parsedMonth}-${parsedDate}T${realHour}:00:00.000Z`;

    data = {
      ...data,
      type: selectedType.toLowerCase(),
      date: date,
      user_id: userId,
    };
    delete data.hour;

    const newEvent = await axios.post(`${BASE_URL}/events/`, data, {
      headers: { Authorization: "bearer " + props.token },
    });
    setEventDate(undefined);
    setSortedMonth(
      year.filter((month) => month[0].month === props.selectedDay.month)[0]
    );
    events.push(newEvent.data);
    props.setUserData({ userData: events });
    setDisplayCreateEventModal(!displayCreateEventModal);
    setShowSelect(false);
  };

  const handleSetDaySelection = () => {
    setShowSelect(!showSelect);
  };

  const twoDimensionMonth = [];

  for (let i = 0; i < sortedMonth.length; i++) {
    const sortedWeek = sortedMonth.filter(
      (element) => element.week === sortedMonth[i].week
    );
    if (twoDimensionMonth.length === 0) {
      twoDimensionMonth.push(sortedWeek);
    } else {
      for (let y = 0; y < twoDimensionMonth.length; y++) {
        const check = twoDimensionMonth.filter(
          (week) => week[0].week === sortedWeek[0].week
        );
        if (check.length === 0) {
          twoDimensionMonth.push(sortedWeek);
        }
      }
    }
  }

  const handleSelectDate = (e) => {
    setEventDate(e.target.id);
    setShowSelect(!showSelect);
  };

  const handleChangeMonth = (e) => {
    if (e.target.alt === "+") {
      if (sortedMonth[0].monthOrder === 11) {
        alert("Calendar is only available for current year");
      } else {
        setSortedMonth(
          year.filter(
            (month) => month[0].monthOrder === sortedMonth[0].monthOrder + 1
          )[0]
        );
      }
    }
    if (e.target.alt === "-") {
      if (sortedMonth[0].monthOrder === 0) {
        alert("Calendar is only available for current year");
      } else {
        setSortedMonth(
          year.filter(
            (month) => month[0].monthOrder === sortedMonth[0].monthOrder - 1
          )[0]
        );
      }
    }
  };

  return (
    <div className={Styles.CreateEventModalContainer}>
      <Button
        handleClick={handleShowModal}
        typeOf={mode === "Dark" ? "DarkMode" : "WhiteButtonCreate"}
        buttonName="+ Create"
      />
      {displayCreateEventModal && (
        <div className={`${Styles.CreateEventModal} ${Styles[mode]}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${Styles.CreateEventModalHeader} ${Styles[mode]}`}>
              {props.displayIcon ? (
                <></>
              ) : (
                <div className={Styles.CancelButtonContainer}>
                  <img
                    src={Cancel}
                    alt="cancel"
                    className={Styles.CancelButton}
                    onClick={handleShowModal}
                  />
                </div>
              )}
            </div>
            <div className={Styles.CreateEventModalDivInput}>
              <div>
                <input
                  placeholder="Add event title"
                  className={`${Styles.CreateEventModalInput} ${Styles[mode]}`}
                  {...register("content")}
                  autoComplete="off"
                  required
                ></input>
              </div>
            </div>
            <div className={Styles.CreateEventModalTypeContainer}>
              {eventTypes.map((events, index) => {
                return (
                  <div
                    key={index}
                    onClick={handleSelectType}
                    className={
                      selectedType === `${events.name}`
                        ? `${Styles.CreateEventModalTypeSelected} ${Styles[mode]}`
                        : `${Styles.CreateEventModalType}`
                    }
                  >
                    <span
                      id={events.name}
                      className={Styles.CreateEventModalTypeTitle}
                    >
                      {events.name}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className={Styles.CreateEventModalTypeContainerSmall}>
              {eventTypes.map((events, index) => {
                return (
                  <div
                    key={index}
                    onClick={handleSelectType}
                    className={
                      selectedType === `${events.name}`
                        ? `${Styles.CreateEventModalTypeSelected} ${Styles[mode]}`
                        : `${Styles.CreateEventModalType}`
                    }
                  >
                    <span
                      id={events.name}
                      className={Styles.CreateEventModalTypeTitle}
                    >
                      {events.sub}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className={Styles.CreateEventModalDivDate}>
              <div
                className={Styles.CreateEventModalDivDateContainer}
                onClick={handleSetDaySelection}
              >
                {eventDate !== undefined ? (
                  <span>
                    {sortedMonth[0].month.substring(0, 3)} {eventDate}{" "}
                    {props.selectedDay.year}
                  </span>
                ) : (
                  <span>
                    {props.selectedDay.month.substring(0, 3)}{" "}
                    {props.selectedDay.date} {props.selectedDay.year}
                  </span>
                )}
              </div>
              <div className={Styles.CreateEventModalDivHourContainer}>
                <select className={Styles.HourSelection} {...register("hour")}>
                  {hours.map((hour, index) => {
                    return (
                      <option key={index} value={hour}>
                        {hour}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className={Styles.CreateEventModalDivDescription}>
              <textarea
                placeholder="Add description"
                type="text"
                {...register("description")}
                autoComplete="off"
                className={`${Styles.CreateEventModalDescriptionInput} ${Styles[mode]}`}
              ></textarea>
            </div>
            <div className={Styles.CreateEventModalFooter}>
              <div className={Styles.CreateEventModalDivFooter}>
                <Button
                  type="submit"
                  typeOf="DarkBlueButton"
                  buttonName="Save"
                />
              </div>
            </div>
          </form>
          {showSelect && (
            <div className={Styles.SelectedDateModal}>
              {year
                .filter(
                  (month) => month[0].month === props.selectedWeek[0].month
                )
                .map((month, index) => {
                  return (
                    <div key={index}>
                      <div className={Styles.SelectedDateModalHeaderSpan}>
                        <span>
                          {sortedMonth[0].month} {sortedMonth[0].year}
                        </span>
                        <div>
                          <div className={Styles.ArrowContainer}>
                            <div
                              onClick={handleChangeMonth}
                              className={Styles.ArrowSingleContainer}
                            >
                              <img
                                src={Arrow}
                                onClick={handleChangeMonth}
                                className={Styles.ArrowMinus}
                                alt="-"
                              />
                            </div>
                            <div
                              onClick={handleChangeMonth}
                              className={Styles.ArrowSingleContainer}
                            >
                              <img
                                src={Arrow}
                                onClick={handleChangeMonth}
                                className={Styles.ArrowPlus}
                                alt="+"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={Styles.SelectedDateModalDayHeader}>
                        {currentWeek.map((days, index) => {
                          return (
                            <div
                              key={index}
                              className={Styles.SelectedDateModalDay}
                            >
                              <span className={Styles.DayLetterSpan}>
                                {days.day.substring(0, 1)}
                              </span>
                              {twoDimensionMonth.map((week, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={Styles.WeekContainer}
                                    onClick={handleSelectDate}
                                  >
                                    {week
                                      .filter((day) => day.place === days.place)
                                      .map((day, index) => {
                                        return (
                                          <div
                                            key={index}
                                            id={day.date}
                                            className={Styles.DateSelector}
                                          >
                                            {day.date}
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.userdata.userData,
    token: state.authentication.token,
  };
};

const mapDispatchToProps = {
  setUserData: setUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
