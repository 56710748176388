import Styles from "./Style.module.css";

function Button({ typeOf, handleClick, buttonName }) {
  return (
    <button
      className={`${Styles.Button} ${Styles[typeOf]}`}
      onClick={handleClick}
    >
      {buttonName}
    </button>
  );
}

export default Button;
