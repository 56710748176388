import Styles from './Style.module.css';

function Toaster({name, type}) {
  return (
    <div className={`${Styles.Toaster} ${Styles[type]}`}>
     {type === "add"?
     <p className={Styles.ToasterText}>{name} is now in your contact-list</p>
      :
     <p className={Styles.ToasterText}>you refused the connection</p>
     } 
    </div>
  )
}

export default Toaster