import { SET_CONTACT_LIST } from "../actions/actions-types";

const initialState = []

export default function contactListReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONTACT_LIST:
      return {
        ...state,
        contactlist: action.payload,
      };
    default:
      return state;
  }
}
