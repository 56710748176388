import axios from "axios";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Button from "../Button/Index";
import { setAuthentication } from "../../actions";

import Styles from "./Style.module.css";

const formSchema = Yup.object().shape({
  username: Yup.string().required("Firstname or pseudo is required"),
  email: Yup.string().email().required("Invalid email format"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password length should be at least 8 characters"),
  confirm: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords must and should match"),
});

function Inscription(props) {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });

  async function onSubmit(data) {
    try {
      const result = await axios.post(`${BASE_URL}/users`, {
        email: data.email,
        username: data.username,
        password: data.password,
      });
      props.setAuthentication({
        isLoggedIn: true,
        id: result.data.id,
        token: result.headers.accesstoken,
        email: data.email,
        username: result.data.username,
      });
      navigate("/calendar");
    } catch (err) {
      if (err.message === "Request failed with status code 404") {
        alert("Aucun compte pour cet email");
      }
      if (err.message === "Request failed with status code 401") {
        alert("le mot de passe fourni est incorrect");
      }
      navigate("/");
    }
  }

  const handleSetConnexion = () => {
    props.setInscription(false);
  };

  return (
    <div className={Styles.InscriptionContainer}>
      <h1>Calendar</h1>
      <span className={Styles.InscriptionSpan}>Create your free account</span>
      <span>to continue with Calendar</span>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={Styles.InscriptionForm}
      >
        <div className={Styles.InscriptionInputContainer}>
          <input
            placeholder="Firstname"
            {...register("username")}
            className={Styles.InscriptionInput}
            required
          ></input>
          <p>{errors.username?.message}</p>
        </div>
        <div className={Styles.InscriptionInputContainer}>
          <input
            placeholder="Email"
            {...register("email")}
            className={Styles.InscriptionInput}
            required
          ></input>
          <p>{errors.email?.message}</p>
        </div>
        <div className={Styles.InscriptionInputContainer}>
          <input
            placeholder="Password"
            {...register("password")}
            type="password"
            autoComplete="new-password"
            className={Styles.InscriptionInput}
            required
          ></input>
          <p>{errors.password?.message}</p>
        </div>
        <div className={Styles.InscriptionInputContainer}>
          <input
            placeholder="Confirm"
            {...register("confirm")}
            type="password"
            className={Styles.InscriptionInput}
            required
          ></input>
          <p>{errors.confirm?.message}</p>
        </div>
        <div className={Styles.InscriptionFooter}>
          {props.setInscription && (
            <p className={Styles.InscriptionBlueP} onClick={handleSetConnexion}>
              Sign In
            </p>
          )}
          <Button type="submit" typeOf="DarkBlueButton" buttonName="Sign Up" />
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setAuthentication,
};

export default connect(mapStateToProps, mapDispatchToProps)(Inscription);
