import { useState } from "react";

import Connexion from "../../components/Connexion/Index";
import Inscription from "../../components/Inscription/Index";

import Styles from "./Style.module.css";

function Landing() {
  const [inscription, setInscription] = useState(false);
  return (
    <div className={Styles.LandingScreen}>
      {inscription ? (
        <Inscription setInscription={setInscription} />
      ) : (
        <Connexion setInscription={setInscription} />
      )}
      <span className={Styles.LandingPageFooter}>© Pelegrand 2022</span>
    </div>
  );
}

export default Landing;
