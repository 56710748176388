import { useState } from "react";
import { useStore } from "react-redux";

import Button from "../Button/Index";

import Styles from "./Style.module.css";

function Modal({
  position,
  modalType,
  modalList,
  long,
  modalButtonName,
  handleClick,
  displayModal,
  setDisplayModal,
}) {
  const [type, setType] = useState(modalType);

  const store = useStore();
  const mode = store.getState().mode.mode;

  const showModal = () => {
    setDisplayModal(!displayModal);
  };

  return (
    <div>
      <Button
        handleClick={showModal}
        typeOf={mode === "Dark" ? "DarkMode" : "WhiteButton"}
        buttonName={modalButtonName}
      />
      {displayModal && (
        <div
          className={`${Styles.Modal} ${Styles[position]} ${Styles[long]} ${Styles[mode]}`}
        >
          {type === "list" && (
            <ul className={Styles.ModalList}>
              {modalList.map((element, index) => {
                return (
                  <div
                    key={index}
                    className={`${Styles.ModalListLi} ${Styles[mode]}`}
                    onClick={(e) => handleClick(e.target.textContent)}
                  >
                    <li value={element}>{element}</li>
                  </div>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}

export default Modal;
