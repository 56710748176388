import { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";

import { setContactList } from "../../actions";
import Toaster from "../Toaster/Index";

import Styles from "./Style.module.css";

export const ContactAsking = (props) => {
  const [pendingsContacts, setpendingsContacts] = useState([]);
  const [showToaster, setShowToaser] = useState(false);
  const [contactName, setContactName] = useState("");
  const [status, setStatus] = useState("");
  const BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchInvitations = async () => {
      const result = await axios.get(
        `${BASE_URL}/users/${props.id}/contacts/pendings`,
        {
          headers: { Authorization: "bearer " + props.token },
        }
      );
      setpendingsContacts(result.data);
    };
    fetchInvitations();
  }, []);

  const handleAnswer = async (e) => {
    if (e.target.innerText === "✔") {
      const data = {
        user_id: props.id,
        user_id1: e.target.id,
        id: e.target.dataset.askedid,
      };
      await axios.post(
        `${BASE_URL}/users/${props.id}/contacts/validation`,
        data,
        {
          headers: { Authorization: "bearer " + props.token },
        }
      );
      setpendingsContacts([
        ...pendingsContacts.filter(
          (contact) => contact.askedBy !== parseInt(e.target.id)
        ),
      ]);
      const newContact = await axios.get(
        `${BASE_URL}/users/${parseInt(e.target.id)}`,
        {
          headers: { Authorization: "bearer " + props.token },
        }
      );
      props.setContactList({
        contactlist: [
          ...props.contactlist,
          {
            id: newContact.data.id,
            username: newContact.data.username,
            email: newContact.data.email,
          },
        ],
      });
      setShowToaser(true);
      setContactName(newContact.data.username);
      setStatus("add");
      setTimeout(() => {
        setShowToaser(false);
      }, 3000);
    } else {
      await axios.delete(
        `${BASE_URL}/users/contacts/pendings/${parseInt(e.target.id)}`,
        {
          headers: { Authorization: "bearer " + props.token },
        }
      );
      setpendingsContacts([
        ...pendingsContacts.filter(
          (contact) => contact.id !== parseInt(e.target.id)
        ),
      ]);
      setShowToaser(true);
      setStatus("refuse");
      setTimeout(() => {
        setShowToaser(false);
      }, 3000);
    }
  };

  return (
    <div>
      <p className={Styles.PendingTitle}>Pending requests</p>
      {pendingsContacts.map((contact) => {
        return (
          <div className={Styles.Contact}>
            <p className={Styles.PendingText}>
              {contact.username} wants to connect
            </p>
            <div className={Styles.PendingCheckContainer}>
              <div
                className={Styles.PendingValidate}
                value="validate"
                id={contact.askedBy}
                data-askedid={contact.id}
                onClick={handleAnswer}
              >
                &#10004;
              </div>
              <div
                className={Styles.PendingRefuse}
                value="refuse"
                id={contact.id}
                onClick={handleAnswer}
              >
                &#10008;
              </div>
            </div>
            <div className={Styles.PendingDetails}>
              <p>{contact.email}</p>
            </div>
          </div>
        );
      })}
      {showToaster && <Toaster name={contactName} type={status} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contactlist: state.contactlist.contactlist,
    token: state.authentication.token,
    id: state.authentication.id,
    email: state.authentication.email,
  };
};

const mapDispatchToProps = {
  setContactList: setContactList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactAsking);
