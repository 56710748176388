import { connect } from "react-redux";

import { setUserPreferences } from "../../actions";
import { eventTypes } from "../Modal/CreateEvent/Index";

import Styles from "./Style.module.css";

export const Preferences = (props) => {
  const handleSelectPreferences = (e) => {
    if (props.userPreferences.includes(e.target.value)) {
      props.setUserPreferences({
        userPreferences: props.userPreferences.filter(
          (ele) => ele !== e.target.value.toLowerCase()
        ),
      });
    } else {
      props.setUserPreferences({
        userPreferences: [
          ...props.userPreferences,
          e.target.value.toLowerCase(),
        ],
      });
    }
  };

  return (
    <div>
      <p className={Styles.PreferencesTitle}>Show on my Calendar</p>
      <div className={Styles.CreateBarCheckBoxContainer}>
        {eventTypes.map((type, index) => {
          return (
            <div className={Styles.CheckBoxContainer} key={index}>
              <input
                type="checkbox"
                name={type.name}
                value={type.name.toLowerCase()}
                onChange={handleSelectPreferences}
                checked={props.userPreferences.includes(
                  type.name.toLocaleLowerCase()
                )}
                className={`${Styles.CheckBox} ${
                  Styles[type.name.toLocaleLowerCase()]
                }`}
              ></input>
              <label hmtlfor={type.name} className={Styles.CheckBoxLabel}>
                {type.name[0].toLocaleUpperCase() + type.name.substring(1)}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreferences: state.userPreferences.userPreferences,
  };
};

const mapDispatchToProps = {
  setUserPreferences: setUserPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
