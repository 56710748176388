import { Routes, Route } from 'react-router-dom';
import Calendar from './screens/Calendar/Index';
import CreateAccount from './screens/CreateAccount/Index';
import Landing from './screens/Landing/Index';

import Styles from './Style.module.css'

function App() {
  return (
    <div className={Styles.App}>
      <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/register" element={<CreateAccount />} />
          <Route path="/calendar" element={<Calendar />}/>
      </Routes>
    </div>
  );
}

export default App;
