import { combineReducers } from "redux";
import authenticationReducer from "./authentication";
import userDataReducer from "./userData";
import modeReducer from "./mode";
import userPreferencesReducer from "./userPreferences";
import contactListReducer from "./contactList";

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  userdata: userDataReducer,
  mode: modeReducer,
  userPreferences: userPreferencesReducer,
  contactlist: contactListReducer,
});

export default rootReducer;
