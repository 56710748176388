import { useState } from "react";
import { connect } from "react-redux";

import { currentWeek, currentDay } from "../CalendarBox/newData";
import { setMode } from "../../actions/index";
import Modal from "../Modal/Index";
import ModeModal from "../Modal/Mode/Index";
import UserModal from "../Modal/UserModal/Index";
import CreateEvent from "../Modal/CreateEvent/Index";

import Styles from "./Style.module.css";
import Menu from "../../assets/icons/menu.png";
import MenuWhite from "../../assets/icons/menuWhite.png";
import Arrow from "../../assets/icons/left-chevron.png";
import ArrowDark from "../../assets/icons/leftDarkMode.png";
import Plus1 from "../../assets/icons/plus1.png";

function Selection(props) {
  const userName = props.username;
  const mode = props.mode;
  const [showUserModal, setShowUserModal] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);

  const handleShowUserModal = () => {
    setShowUserModal(!showUserModal);
  };

  const handleShowCreateEventModal = () => {
    setShowCreateEventModal(!showCreateEventModal);
  };

  const handleChangeMinus = () => {
    if (props.displayWeek) {
      const filtredYear = props.currentYear
        .map((month) =>
          month.filter((days) => days.week === props.selectedWeek[0].week - 1)
        )
        .filter((element) => element.length > 0);
      if (filtredYear[0].length < 7) {
        filtredYear[0].push(...filtredYear[1]);
      }
      props.setSelectedWeek(filtredYear[0]);
      props.setSelectedDay(filtredYear[0][0]);
    }
    if (props.displayDay) {
      const filtredYear = props.currentYear
        .map((month) =>
          month.filter((days) => days.count === props.displayDay.count - 1)
        )
        .filter((element) => element.length > 0);
      props.setDisplayDay(filtredYear[0][0]);
      props.setSelectedDay(filtredYear[0][0]);
    }
  };

  const handleChangePlus = () => {
    if (props.displayWeek) {
      const filtredYear = props.currentYear
        .map((month) =>
          month.filter((days) => days.week === props.selectedWeek[0].week + 1)
        )
        .filter((element) => element.length > 0);
      if (filtredYear[0].length < 7) {
        filtredYear[0].push(...filtredYear[1]);
      }
      props.setSelectedWeek(filtredYear[0]);
      props.setSelectedDay(filtredYear[0][0]);
    }
    if (props.displayDay) {
      const filtredYear = props.currentYear
        .map((month) =>
          month.filter((days) => days.count === props.displayDay.count + 1)
        )
        .filter((element) => element.length > 0);
      props.setDisplayDay(filtredYear[0][0]);
      props.setSelectedDay(filtredYear[0][0]);
    }
  };

  const handleToday = () => {
    props.setSelectedWeek(currentWeek);
  };

  const handleTodayDisplay = () => {
    props.setDisplayDay(currentDay);
  };

  const formatValue = ["Day", "Week"];

  const formatValueSize = `_${formatValue.length.toString()}`;

  const handleSelectFormat = (format) => {
    if (format === "Week") {
      props.setDisplayWeek(true);
      props.setDisplayDay(false);
      props.setButtonName(format);
      props.setDisplayModal(false);
      props.setSelectedWeek(currentWeek);
    }
    if (format === "Day") {
      props.setDisplayWeek(false);
      props.setDisplayDay(currentDay);
      props.setButtonName(format);
      props.setDisplayModal(false);
    }
  };

  const handleSelectMode = (selected) => {
    props.setMode({ mode: selected });
    props.setDisplayModeModal(false);
  };

  return (
    <div className={`${Styles.SelectionContainer} ${Styles[mode]}`}>
      <div
        className={`${Styles.SelectionContainerButtonContainer} ${Styles[mode]}`}
        onClick={props.handleClick}
      >
        <img
          alt="Burger"
          className={`${Styles.SelectionContainerBurgerButton} ${Styles[mode]}`}
          src={mode === "Dark" ? MenuWhite : Menu}
        />
        <img
          alt="Burger"
          className={`${Styles.SelectionContainerBurgerButtonSmallScreen}`}
          src={mode === "Dark" ? Plus1 : Plus1}
          onClick={handleShowCreateEventModal}
        />
      </div>
      <div className={`${Styles.SelectionContainerNavigation} ${Styles[mode]}`}>
        {props.displayWeek && (
          <div
            className={`${Styles.SelectionContainerNavigationToday} ${Styles[mode]}`}
            onClick={handleToday}
          >
            <p>Today</p>
          </div>
        )}
        {props.displayDay && (
          <div
            className={`${Styles.SelectionContainerNavigationToday} ${Styles[mode]}`}
            onClick={handleTodayDisplay}
          >
            <p>Today</p>
          </div>
        )}
        <div
          className={`${Styles.SelectionContainerNavigationArrowContainer} ${Styles[mode]}`}
        >
          <div className={`${Styles.ArrowBigContainer} ${Styles[mode]}`}>
            <div
              className={`${Styles.ArrowContainer} ${Styles[mode]}`}
              onClick={handleChangeMinus}
            >
              <img
                className={`${Styles.SelectionContainerArrowPrevious} ${Styles[mode]}`}
                src={mode === "Dark" ? ArrowDark : Arrow}
                alt="Previous-Week"
              ></img>
            </div>
            <div
              className={`${Styles.ArrowContainer} ${Styles[mode]}`}
              onClick={handleChangePlus}
            >
              <img
                className={`${Styles.SelectionContainerArrowNext} ${Styles[mode]}`}
                src={mode === "Dark" ? ArrowDark : Arrow}
                alt="Next-Week"
              ></img>
            </div>
          </div>
          <div className={`${Styles.MonthBigContainer} ${Styles[mode]}`}>
            {props.displayWeek && (
              <>
                {props.selectedWeek[0].month === props.selectedWeek[6].month ? (
                  <>
                    <p className={Styles.MonthTitle}>
                      {props.selectedWeek[0].month} {props.selectedWeek[0].year}
                    </p>
                    <p className={Styles.MonthTitleSmallScreen}>
                      {props.selectedWeek[0].month.substring(0, 3)}{" "}
                      {props.selectedWeek[0].year}
                    </p>
                  </>
                ) : (
                  <p>
                    {props.selectedWeek[0].month.substring(0, 3)}-
                    {props.selectedWeek[6].month.substring(0, 3)}{" "}
                    {props.selectedWeek[0].year}
                  </p>
                )}
              </>
            )}
            {props.displayDay && (
              <>
                <p className={Styles.MonthTitle}>
                  {props.displayDay.month} {props.displayDay.year}
                </p>
                <p className={Styles.MonthTitleSmallScreen}>
                  {props.displayDay.month.substring(0, 3)}{" "}
                  {props.displayDay.year}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <Modal
          position="Top-Mid"
          modalType="list"
          modalList={formatValue}
          long={formatValueSize}
          modalButtonName={props.buttonName}
          handleClick={handleSelectFormat}
          setDisplayModal={props.setDisplayModal}
          displayModal={props.displayModal}
        />
      </div>
      {userName && (
        <div className={`${Styles.UserDetailsContainer} ${Styles[mode]}`}>
          <ModeModal
            position="Top-Mid"
            // modalType="list"
            modalButtonName="Mode"
            handleClick={handleSelectMode}
            setDisplayModeModal={props.setDisplayModeModal}
            displayModeModal={props.displayModeModal}
          />
          <div
            className={`${Styles.UserDetails} ${Styles[mode]}`}
            onClick={handleShowUserModal}
          >
            {userName.substring(0, 1).toUpperCase()}
          </div>
        </div>
      )}
      {showUserModal && <UserModal />}
      {showCreateEventModal && (
        <CreateEvent
          selectedDay={props.selectedDay}
          selectedWeek={props.selectedWeek}
          modalState="true"
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    mode: state.mode.mode,
    username: state.authentication.username,
    logged: state.authentication.isLoggedIn,
  };
};

const mapDispatchToProps = {
  setMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Selection);
