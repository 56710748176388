import { SET_MODE } from "../actions/actions-types";

const initialState = {
  mode: "",
};

export default function modeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    default:
      return state;
  }
}
