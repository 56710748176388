import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setSignOut } from "../../actions";
import Button from "../Button/Index";

import Styles from "./Style.module.css";

function Footer(props) {
  const navigate = useNavigate();

  const handleSignOut = () => {
    props.setSignOut();
    navigate("/");
  };

  return (
    <div className={Styles.Footer}>
      <Button
        typeOf="WhiteButton2"
        buttonName="Sign out"
        handleClick={handleSignOut}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSignOut: setSignOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
