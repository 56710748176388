import { connect } from "react-redux";

import CreateEvent from "../Modal/CreateEvent/Index";
import ContactSearch from "../ContactSearch/Index";
import Preferences from "../Preferences/Index";
import ContactAsking from "../ContactAsking/Index";

import Styles from "./Style.module.css";

function CreateBar(props) {
  const mode = props.mode.mode;

  return (
    <div className={`${Styles.CreateBarContainer} ${Styles[mode]}`}>
      <div className={`${Styles.CreateBarContainerHeader} ${Styles[mode]}`}>
        <div
          className={`${Styles.CreateBarContainerHeaderButton} ${Styles[mode]}`}
        >
          <CreateEvent
            selectedDay={props.selectedDay}
            selectedWeek={props.selectedWeek}
          />
        </div>
      </div>
      <div className={`${Styles.CreateBarContainerBody} ${Styles[mode]}`}>
        <Preferences />
        <ContactSearch />
        <ContactAsking />
      </div>
      <div className={`${Styles.CreateBarContainerFooter} ${Styles[mode]}`}>
        <p className={Styles.CalendarVersion}>Calendar 0.5.22</p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    mode: state.mode,
    authentication: state.authentication,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBar);
